<template>
  <form-field
    :name="field.name"
    :hide="field.hide"
  >
    <v-radio-group
      v-model="field.value"
      v-bind="field.props"
    >
      <v-radio
        label="Student"
        ripple
        value="Y"
      />
      <v-radio
        label="Not Student"
        ripple
        value="N"
      />
    </v-radio-group>
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  mounted: function () {
    this.field.value = 'N'
  },
}
</script>
